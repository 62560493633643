@font-face {
    font-family: 'Myriad Pro';
    font-weight: 600;
    font-style: normal;
    src: url('./fonts/MyriadPro-Semibold.woff2') format('woff2'),
         url('./fonts/MyriadPro-Semibold.woff') format('woff'),
         url('./fonts/MyriadPro-Semibold.ttf') format('truetype');
  }

@font-face {
    font-family: 'Myriad Pro';
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/MyriadPro-Regular.eot');
    src: url('./fonts/MyriadPro-Regular.woff') format('woff'),
         url('./fonts/MyriadPro-Regular.ttf') format('truetype');
    }
