.radioGroup {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  height: 44px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--grey);
  padding-bottom: 10px;
}

.label {
  padding: 0;
  margin: 0 24px 0 0;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.listItem {
  padding: 0;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.listItem:last-of-type {
  margin: 0;
}

.listItem::before {
  content: none;
}

.radioBtn {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid var(--grey);
  padding: 0;
  margin: 0 4px 0 0;
  box-sizing: border-box;
  cursor: pointer;
}

.listItem>input:checked+.radioBtn {
  border: 6px solid var(--technolab-blue);
}

.radioLabel {
  padding: 0;
  margin: 0;
  cursor: pointer;
}