.footer {
  width: 90%;
  max-width: 1280px;
  margin: 50px 0 36px;
  flex-shrink: 0;
  position: relative;
  z-index: 3;
}

.copyright {
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.21;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .footer {
    margin: 48px 19px 36px;
  }
}

@media (max-width: 880px) {
  .copyright {
    font-size: 12px;
  }
}