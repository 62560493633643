.header {
  width: 100%;
  position: fixed;
  z-index: 100;
  background-color: var(--header-background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_no-back {
  background: none;
}

.container {
  width: 1200px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

@media (max-width: 1250px) {

  .container {
    width: 90%;
  }

}

@media (max-width: 1200px) {

  .container {
    flex-direction: column;
    width: 95%;
  }

}

@media (max-width: 600px) {

  .container {
    width: 400px;
  }

}

@media (max-width: 450px) {

  .container {
    width: 95vw;
  }

}