.main {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  align-items: center;
  z-index: 3;
  scroll-snap-type: y mandatory;
}

.main::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, .3);
  z-index: 3;
  position: fixed;

}

.main__back {
  background-image: url(../../../images/main/main-banner.png);
  background-position: center top;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--technolab-blue);

}

.main__video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  z-index: 2;
}

.main__section {
  min-height: 100vh;
  padding: 0;
  box-sizing: border-box;
  background-position: center top;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.main__linkbar {
  position: absolute;
  right: 100px;
  width: 90px;
  height: auto;
  list-style: none;
  background-color: rgba(255, 255, 255, .8);
  box-shadow: 0 0 10px rgba(9, 154, 215, .3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  padding: 20px 20px;
  margin: 0;
  border-radius: 20px;
  z-index: 4;
}

/*

.main__navbar {
  margin: 0;
  padding: 0;
  height: var(--banner-navbar-height-big);
  width: 100%;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  align-items: center;
  gap: 140px;
  position: relative;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  list-style: none;
}

.main__navbar * {
  height: 100%;
}
*/

@media (max-width: 1200px) {
  .main__linkbar {
    width: 70px;
    right: 5vw;
  }
}


@media (max-width: 900px) {
  .main__linkbar {
    right: 1vw;
    width: 50px;
  }
}

@media (max-width: 700px) {
  .main__linkbar {
    position: static;
    flex-direction: row;
    height: 50px;
    width: auto;
    margin: 100px 0;
  }
}