.plus-btn {
  background: var(--tile-background);
  background-image: url(../../../images/btn-add.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 30% 30%;
  border: none;
  border-radius: 30px;
  min-height: 50px;
  width: 100%;
  box-shadow: rgba(185, 183, 183, .2) 0 0 10px;
  transition: all linear .3s;
  opacity: .6;
}


.plus-btn:hover {
  transform: scale(1.01);
  background-size: 33% 33%;
  cursor: pointer;
  opacity: 1;
}