.navbar {
  border-radius: 9px;
  display: flex;
  margin-right: 25px;
}

.link {
  margin-right: 14px
}

.link:hover {
  color: var(--technolab-blue);
}

@media (max-width: 1200px) {
  .navbar {
    margin-right: 0;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0 0;
  }


  .link {
    margin: 0 5px;
  }
}

@media (max-width: 750px) {

  .navbar {
    width: 75%;
  }
}

@media (max-width: 600px) {

  .navbar {
    width: 100%;
  }
}