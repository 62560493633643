.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, .6);
  transition: background-color .3s linear;
}

.popup_hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .1);
}

.container {
  width: 50%;
  height: min-content;
  max-height: 70%;
  box-sizing: content-box;
  border-radius: 30px;
  background-color: var(--header-background);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden auto;
  margin-top: 79.5px;
  padding: 40px 10px;
  scrollbar-color: var(--technolab-blue) var(--scrollbar-color);
  scrollbar-width: thin;
}

.container::-webkit-scrollbar {
  width: 8px;
}

.container::-webkit-scrollbar-track {
  background: none;
  margin: 40px;
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--technolab-blue);
  border-radius: 15px;
  padding-right: 0px;
  border: 2px solid var(--scrollbar-color);
}

@media(max-width:1100px) {
  .container {
    width: 70%;
  }
}

@media(max-width:500px) {
  .container {
    height: auto;
  }
}