.listGroup {
  margin-top: 15px;
  border: solid 1px var(--technolab-blue);
  padding: 10px;
  border-radius: 10px;
}

.listGroup h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey);
}

.listGroup ul {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.button {
  background-color: var(--technolab-blue);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inputGroup div {
  width: 75%;
}