.btns {
  position: absolute;
  top: -35px;
  right: 0;
  border-radius: 30px;
  z-index: 40;
  height: 30px;
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--technolab-blue);
  opacity: .3;
  transition: all linear .3s;
}

.btns:hover {
  opacity: 1;
}

.btn {
  background: none;
  border: none;
  width: 15px;
  height: 15px;
  margin: 0 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  transition: all linear .3s;
}

.btn:hover {
  cursor: pointer;
  transform: scale(1.15);
}

.btn_type_edit {
  background-image: url(../../../images/btn-edit.svg);
}

.btn_type_delete {
  background-image: url(../../../images/btn-delete.svg);
}

.btn_type_close {
  background-image: url(../../../images/btn-cross.svg);
  background-size: 200% 200%;
}