.event {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 45px 0 0 0;
  position: relative;
}

.event__logo {
  max-width: 75%;
  max-height: 80px;
  align-self: flex-start;
}

.event__title {
  color: var(--technolab-blue);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  margin: 20px 0;
  text-align: justify;
}

.event__date {
  margin: 0;
}

.event__archive {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--technolab-blue);
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
  opacity: .7;
}

@media (max-width: 1100px) {

  .event__logo {
    max-height: 80px;
    align-self: flex-start;
  }

  .event__title {
    text-align: right;
  }
}

@media (max-width: 900px) {

  .event__logo {
    width: 230px;
  }

}

@media (max-width: 550px) {

  .event__logo {
    width: 150px;
  }

  .event {
    flex-direction: column;
  }
}