.hoverOpacity {
  opacity: 1;
  transition: all .3s linear;
}

.hoverOpacity:hover {
  opacity: .8;
}

.dnd {
  box-sizing: border-box;
  width: 100%;
  max-width: 742px;
  margin: 0;
}

.dnd h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey);
}

.info {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0;
}

.info li {
  width: max-content;
}

.info li::before {
  content: none;
}


.container {
  box-sizing: border-box;
  width: 100%;
  min-height: 110px;
  border: 1px dashed var(--technolab-blue);
  border-radius: 8px;
  padding: 16px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.container_dragging {
  background-color: var(--header-background);
  filter: blur(1.5px);
}

.inputLabel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: var(--technolab-blue);
  cursor: pointer;
}

.files {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 64px);
  gap: 10px;
  justify-content: center;
  align-items: top;
}

.file::before {
  content: none;
}

.file {
  width: 64px;
  height: 90px;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid var(--technolab-blue);
  cursor: default;
  object-fit: contain;
}

.name {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: var(--grey);
  margin: 4px 0 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.crossBtn {
  background: none;
  background-image: url(../../../images/btn-add.svg);
  background-color: var(--background-color);
  border-radius: 100%;
  transform: rotate(45deg);
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 3.5px;
  border: none;
  cursor: pointer;
}

.plusBtn {
  background-image: url(../../../images/btn-add.svg);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid var(--technolab-blue);
  cursor: pointer;
}

.countInfo {
  font-size: 16px;
  line-height: 20px;
  color: var(--grey);
  text-align: center;
  padding: 0;
  margin: 16px 0 0;
}

.error {
  font-size: 16px;
  color: red;
  text-align: center;
}