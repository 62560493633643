:root {
  --background-color: #d5d4d4;
  --technolab-blue: #099ad7;
  --grey: #3d3d3d;
  --dark-grey: #030303;
  --aero-color: #be312ae8;
  --basic-color: #509ADBe8;
  --elementary-color: #63BD4Ce8;
  --preliminary-color: #D8B13Ce8;
  --professional-color: #3776C5e8;
  --research-color: #A85B9Fe8;
  --machines-color: #C63332;
  --tile-background: rgba(241, 240, 240, .65);
  --header-background: rgba(241, 240, 240, .9);
  --scrollbar-color: #E9E9E9;
  --banner-navbar-height-big: 140px;
  --banner-navbar-height-medium: 120px;
  --banner-navbar-height-small: 100px;
}


/* Кадры */


@keyframes disappear-opacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes appear-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#hidden {
  display: hidden !important;
}

p {
  font-size: 18px;
  line-height: 1.4;
  text-align: justify;
  z-index: 5;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1 {
  font-size: 38px;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

a {
  color: var(--grey);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  transition: color .3s linear;
}

a:hover {
  cursor: pointer
}


/* Адаптивность */

@media (max-width: 1050px) {

  p,
  a {
    font-size: 16px;
  }

  h1 {
    font-size: 54px;
  }


}

@media (max-width: 750px) {

  p,
  a {
    font-size: 14px;
  }

  h1 {
    font-size: 48px;
  }

}

/* Страница */

* {
  font-family: 'Myriad Pro', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.background {
  background-color: var(--background-color);
}

.page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-wrap: break-word;
  width: 100%;
  margin: 0 auto;
  scroll-behavior: smooth;
}


/* Анимации */

.disappear-opacity {
  animation: disappear-opacity .3s linear forwards;
}

.appear-opacity {
  animation: appear-opacity .3s linear forwards;
}


/* Стандартная секция */

.section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  align-items: flex-start;
  max-width: 1400px;
  width: 75%;
  margin-top: 130px;
  box-sizing: border-box;
  position: relative;
  z-index: 20;
}


/* Скрытый элемент */

.hidden {
  display: none;
  visibility: hidden;
}


/* Фильтры изображений */

.grayscale {
  filter: grayscale(80%);
}


/* Стандартный текст */

.text_uppercase {
  text-transform: uppercase;
  font-weight: bold;
}

/* Выделение текста цветом модуля */

.highlighted-text {
  color: var(--technolab-blue)
}

.highlighted-text_level_aero {
  color: var(--aero-color)
}

.highlighted-text_level_basic {
  color: var(--basic-color)
}

.highlighted-text_level_elementary {
  color: var(--elementary-color)
}

.highlighted-text_level_preliminary {
  color: var(--preliminary-color)
}

.highlighted-text_level_professional {
  color: var(--professional-color)
}

.highlighted-text_level_research {
  color: var(--research-color)
}

.highlighted-text_level_machines {
  color: var(--machines-color)
}

/* Адаптивность */

@media (max-width: 1400px) {
  .section {
    width: 90%;
    margin-top: 160px;
  }
}

@media (max-width: 1050px) {
  .section {
    margin-top: 140px;
  }
}