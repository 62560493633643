.card {
  background-color: var(--tile-background);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 160px auto;
  background-image: url('../../../images/cards/default-background.png');
  width: 390px;
  height: 460px;
  border-radius: 30px;
  position: relative;
  z-index: 3;
}

.card_hidden {
  opacity: .8;
  border: dashed 2px var(--technolab-blue);
  filter: grayscale(.5)
}

/* МОДИФИКАТОРЫ КАРТОЧКИ ПО ТИПУ */

.card_type_with-menu {
  position: static;
  height: 550px;
  border-radius: 30px;
  display: -ms-grid;
  display: grid;
  grid-template-areas: 'slider slider slider'
    '. title pic'
    '. article pic'
    '. price pic'
    'navbar navbar navbar';
  -ms-grid-columns: 40px auto 40px;
  grid-template-columns: 40px auto 40px;
  -ms-grid-rows: 280px auto;
  grid-template-rows: 280px auto;
  justify-items: start;
  overflow: hidden;
  height: 550px;
}

.card_type_with-btn {
  display: -ms-grid;
  display: grid;
  grid-template-areas: 'slider slider slider'
    '. title title'
    '. article btn'
    '. price btn';
  column-gap: 38px;
  -ms-grid-columns: 0 1.8fr 2fr;
  grid-template-columns: 0 1.8fr 2fr;
  -ms-grid-rows: 280px 70px auto;
  grid-template-rows: 280px 70px auto;
  justify-items: start;
  -webslider-transition: -webslider-transform .3s ease-in-out;
  transition: -webslider-transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webslider-transform .3s ease-in-out;
  position: relative;
}

/* МОДИФИКАТОРЫ КАРТОЧКИ ПО СОДЕРЖИМОМУ(ФОН) */

.card_level_research {
  background-image: url('../../../images/cards/research-background.png');
}

.card_level_professional {
  background-image: url('../../../images/cards/professional-background.png');
}

.card_level_preliminary {
  background-image: url('../../../images/cards/preliminary-background.png');
}

.card_level_machines {
  background-image: url('../../../images/cards/machines-background.png');
}

.card_level_elementary {
  background-image: url('../../../images/cards/elementary-background.png');
}

.card_level_basic {
  background-image: url('../../../images/cards/basic-background.png');
}

.card_level_aero {
  background-image: url('../../../images/cards/aero-background.png');
}

/* ЭЛЕМЕНТЫ КАРТОЧКИ */

.card__title {
  grid-area: title;
  font-weight: bold;
  text-align: left;
  font-size: 15px;
  margin: 0;
  color: var(--grey);
  max-width: 90%;
}

.card__article {
  grid-area: article;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  align-self: center;
  color: var(--grey);
  text-align: left;
}

.card__price {
  grid-area: price;
  font-size: 15px;
  margin: 0;
  font-weight: bold;
}

.card__slider {
  grid-area: slider;
  margin-bottom: 50px;
}

/* ССЫЛКА-КНОПКА КАРТОЧКИ */

.card__link-btn {
  background-image: url('../../../images/cards/default-btn.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  width: 130px;
  height: 90px;
  justify-self: start;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  font-size: 13.5px;
  text-align: center;
  margin: 0;
  padding: 0 0 20px 0;

  color: #fff;
  text-transform: uppercase;

  opacity: 1;
  grid-area: btn;
  box-sizing: border-box;
  transition: all .3s linear;

  position: absolute;
  right: 30px;
  bottom: 20px;
}


.card__link-btn:hover {
  color: #fff;
}

.card__link-btn_level_machines {
  background-image: url('../../../images/cards/machines-btn.png');
}

.card__link-btn_level_research {
  background-image: url('../../../images/cards/research-btn.png');
}

.card__link-btn_level_professional {
  background-image: url('../../../images/cards/professional-btn.png');
}

.card__link-btn_level_preliminary {
  background-image: url('../../../images/cards/preliminary-btn.png');
}

.card__link-btn_level_elementary {
  background-image: url('../../../images/cards/elementary-btn.png');
}

.card__link-btn_level_basic {
  background-image: url('../../../images/cards/basic-btn.png');
}

.card__link-btn_level_aero {
  background-image: url('../../../images/cards/aero-btn.png');
}

@media (max-width: 1100px) {

  .card {
    background-color: rgba(241, 240, 240, .9);
  }
}



@media (max-width: 500px) {

  .card {
    margin-bottom: 10px;
    width: 290px;
    height: 330px;
    background-size: 140px auto;
  }

  .card_type_with-btn {
    -ms-grid-columns: 15px 115px auto;
    grid-template-columns: 0 115px auto;
    -ms-grid-rows: 180px auto;
    grid-template-rows: 180px auto;
    column-gap: 20px;
  }

  .card_type_with-menu {
    height: 400px;
    -ms-grid-columns: 15px auto;
    grid-template-columns: 15px auto;
    -ms-grid-rows: 180px auto;
    grid-template-rows: 180px auto;
    grid-template-areas: 'slider slider'
      '. title'
      '. article'
      '. price'
      'navbar navbar';
  }

  .card__img {
    height: 140px;
    width: 200px;
  }

  .card__price {
    font-size: 12px;
  }

  .card__article {
    font-size: 13px;
  }

  .card__title {
    font-size: 12px;
    width: 90%
  }

  .card__link-btn {
    width: 110px;
    height: 70px;
    font-size: 10px;
    padding-bottom: 15px;
  }
}