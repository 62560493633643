.form-btn {
  height: 45px;
  background: var(--technolab-blue);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 16px;
  transition: all linear .3s;
}

.form-btn:hover {
  background-color: var(--grey);
  cursor: pointer;
}

.form-btn_inactive {
  opacity: .2;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  color: #000;
}

.form-btn_inactive:hover {
  opacity: .2;
  cursor: default;
}

@media(max-width: 500px) {

  .form-btn {
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 14px;
  }
}