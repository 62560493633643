.catalog {
  width: 90%;
}

.catalog__list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 390px);
  grid-gap: 60px 50px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 950px) {
  .catalog__list {
    grid-template-columns: repeat(auto-fit, 390px);
    gap: 50px;
  }
}

@media (max-width: 500px) {
  .catalog__list {
    grid-template-columns: repeat(auto-fit, 290px);
    grid-gap: 40px;
  }
}