.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-self: center;
  padding: 0;
  width: 400px;
  box-sizing: border-box;
  justify-self: flex-start;
}

.form__container {
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin-top: 20px;
}

.form__container h2 {
  text-align: center;
}

.form__btn {
  height: 45px;
  background: var(--technolab-blue);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 16px;
  transition: all linear .3s;
}

.form__btn:hover {
  background-color: var(--grey);
  cursor: pointer;
}

.form__btn_inactive {
  opacity: .2;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  color: #000;
}

.form__btn_inactive:hover {
  opacity: .2;
  cursor: default;
}

.form__err-message {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #EE3465;
  padding-bottom: 20px;
}

@media (max-width: 650px) {
  .form {
    width: auto;
  }
}

@media(max-width: 500px) {

  .form__container {
    display: flex;
    flex-direction: column;
  }

  .form__btn {
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 14px;
  }
}