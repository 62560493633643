.logo {
  width: 250px;
  height: 34px;
  margin: 0;
}

@media (max-width: 1050px) {

  .logo {
    width: 205px;
    height: 28px;
  }
}

@media (max-width: 950px) {

  .logo {
    margin: 0;
  }
}