.image-loader {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px var(--technolab-blue);
  display: flex;
  flex-direction: column;
  text-align: center;
}

.image-loader__result {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: var(--technolab-blue);
  padding: 30px;
  box-sizing: border-box;
}

.image-loader__title {
  text-align: center;
  color: var(--technolab-blue);
  margin-bottom: 20px;
}

.image-loader__again-btn {
  z-index: 12;
}