.event-page {
  display: -ms-grid;
  display: grid;
  grid-template-areas: 'back about'
    'title about'
    'list about';
  -ms-grid-columns: auto 20px 60%;
  grid-template-columns: auto 60%;
  -ms-grid-rows: 80px 20px 220px 20px auto;
  grid-template-rows: 80px 220px auto;
  gap: 20px;
}

.event-page__back-btn {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  margin: 0;
  padding: 0;
  grid-area: back;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  background: none;
  border: none;
}

.event-page__back-btn:hover {
  cursor: pointer;
  -webkit-transform: scaleX(1.01) translateX(-5px);
  -ms-transform: scaleX(1.01) translateX(-5px);
  transform: scaleX(1.01) translateX(-5px);
}

.event-page__back-btn:hover .event-page__back-btn-text {
  color: var(--technolab-blue);
}

.event-page__title-tile {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: title;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-transform: uppercase;
  color: var(--technolab-blue);
  height: 100%;
  background: #f1f0f0;
}

.event-page__title {
  margin: 0;
  padding: 0;
  color: var(--technolab-blue);
  font-size: 28px;
}

.event-page__logo {
  width: 60%;
  max-width: 250px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.event-page__list {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: list;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-rows: repeat(auto-fit, 100px);
  gap: 15px;
}

.event-page__list li {
  height: 100px;
}

.event-page__link {
  height: 100px;
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 40% auto;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.event-page__link_type_highlighted {
  background-color: var(--technolab-blue);
  color: #f1f0f0;
  -webkit-transition: all .4s linear;
  -o-transition: all .4s linear;
  transition: all .4s linear;
  /* background-image: url(../../../images/events/bridge-registration.png); */
}

.event-page__link_type_field {
  background-image: url(../../../images/events/bridge-field.png);
}

.event-page__link_type_guide {
  background-image: url(../../../images/events/bridge-guide.png);
}

.event-page__link_type_products {
  background-image: url(../../../images/events/bridge-products.png);
}

.event-page__link-text {
  width: 60%;
  text-align: left;
  padding: 0;
  margin: 0 0 0 30px;
}

.event-page__about {
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  -ms-grid-column: 3;
  grid-area: about;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.event-page__scores {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.event-page__table {
  max-width: 500px;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin-top: 15px;
}

@media (max-width: 1250px) {

  .event-page__title {
    font-size: 24px;
  }

}

@media (max-width: 1200px) {

  .event-page {
    display: -ms-grid;
    display: grid;
    grid-template-areas: 'back list'
      'title list'
      'about about';
    -ms-grid-rows: 80px 20px 350px 20px 500px;
    grid-template-rows: 80px 350px 500px;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .event-page__back-btn {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .event-page__title-tile {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .event-page__list {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 3;
  }

  .event-page__about {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

}

@media (max-width: 750px) {

  .event-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }


  .event-page__about {
    max-height: 500px;
  }

  .event-page__title {
    font-size: 18px;
    margin-top: 20px;
  }


  .event-page__link {
    background-size: auto 85%;
  }

}

@media (max-width: 460px) {

  .event-page__link {
    background-image: none;
  }

  .event-page__link-text {
    width: 100%;
  }

}