@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.preloader {
  cursor: progress;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 400;
  padding: 20px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, .3);
  border-radius: 100%;
  transform: scale(0, 0);
  transition: all 1s ease-out;
}

.preloader_visible {
  transform: scale(1, 1);
  border-radius: 0;
}


.container {
  width: 200px;
  height: 200px;
  position: relative;
}

.round {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, .3);
  border-radius: 100px;
}

.round::after,
.round::before {
  content: '';
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100px;
}

.round::after {
  background: var(--technolab-blue);
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.round::before {
  z-index: 10;
  background: rgba(255, 255, 255, .3);
  animation: preloader-inside-red 1s ease-in-out infinite;
}