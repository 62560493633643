.selectWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey);
}

.select {
  background: var(--tile-background);
  border-radius: 8px;
  border: none;
  padding: 15px;
  transition: all .3s linear;
  color: var(--grey);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 0;
  cursor: pointer;
}

.listContainer {
  height: max-content;
  max-height: 200px;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  top: 80px;
  z-index: 40;
  background-color: #ffffffe3;
}

.hidden {
  display: none;
}

.listItem {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 8px;
  color: var(--grey);
}

.listItem:hover {
  border: 1px solid var(--technolab-blue);
  color: var(--technolab-blue);
}

.listItem_active {
  background-color: var(--technolab-blue);
  color: #fff;
}

.listItem_active:hover {
  background-color: var(--technolab-blue);
  color: #fff;
}