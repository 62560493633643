.brand-link {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 15px 0;
  box-sizing: border-box;
  margin: 0;
  transition: all .3s linear;
}

.brand-link__img {
  height: auto;
  width: 100%;
}

.brand-link:hover {
  transform: scale(1.02);
}

@media (max-width: 700px) {

  .brand-link {
    padding: 0 15px;
    height: 100%;
  }

  .brand-link__img {
    height: 50px;
    width: auto;
  }
}