.btn {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-image: url(../../../images/btn-profile.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  border: none;
  z-index: 120;
  opacity: 1;
  transition: all .3s linear;
}

.btn:hover {
  cursor: pointer;
  opacity: .6;
}

.btn_active {
  border: 2px solid var(--technolab-blue);
}